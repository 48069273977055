<template>
  <div class="login__wrap">
    <div class="login__head">
      <el-image class="logoImg" :src="require('@/assets/img/logo.png')"></el-image>
      <div class="portMarking">政府端</div>
    </div>
    <div class="login__bg">
      <div class="login__main">
        <div class="login-title">研学旅行专业服务平台</div>

        <!-- 登录 -->
          <div class="register__box mt30" v-if="activePage == 1">
          <div class="loginTop" style="text-align:center;font-size:20px;">欢迎登录</div>
            <div class="login__box">
              <el-form
                class="sign__main"
                ref="elForm"
                :model="form.data"
                :rules="form.rules"
                @submit.prevent="submit"
              >
                <el-form-item prop="account">
                  <el-input v-model.trim="form.data.account" maxlength="11" clearable placeholder="请输入手机号码" >
                    <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/user.png')" />
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input v-model.trim="form.data.password" clearable show-password placeholder="请输入密码">
                    <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/key.png')" />
                  </el-input>
                </el-form-item>
                <el-form-item style="margin-top:-10px;">
                  <el-checkbox v-model="checked">下次自动登录</el-checkbox>
                </el-form-item>
              </el-form>
              <el-button class="theneBtn" style="margin-bottom:20px;width: 100%;padding: 15px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
              <!-- <div class="text1">没有账号，<span style="color:#007DF2;cursor: pointer;" @click="go_zhuce">立即注册</span></div> -->
            </div>
          </div>
          
          <!-- 注册 -->
         <div class="register__box mt30" v-if="activePage == 2">
          <div class="loginTop">有账号，<span style="color:#007DF2;font-size:16px;" @click="go_login">立即登录</span></div>
          <div class="login__box">
            <el-form
              class="sign__main"
              ref="elForm"
              :model="form.data"
              :rules="form.rules"
              @submit.prevent="submit">
              <el-form-item prop="account">
                <el-input v-model.trim="form.data.account" maxlength="11" clearable autocomplete="new-password" placeholder="请输入手机号码" >
                  <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/user.png')" />
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model.trim="form.data.password" clearable show-password autocomplete="new-password" placeholder="请设置6-20位登录密码">
                  <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/key.png')" />
                </el-input>
              </el-form-item>
              <el-form-item prop="account" style="margin-bottom:0 !important;">
                <!-- <el-input v-model.trim="form.data.account" maxlength="11" clearable autocomplete="new-password" placeholder="请输入手机号码" >
                  <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/user.png')" />
                </el-input> -->
              </el-form-item>
              <el-form-item prop="checkPass">
                <el-input v-model.trim="form.data.checkPass" clearable show-password autocomplete="new-password" placeholder="请再次输入密码以确认">
                  <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/key.png')" />
                </el-input>
              </el-form-item>
              
              <el-form-item prop="code">
                <el-input
                  v-model="form.data.code"
                  clearable
                  placeholder="请输入短信验证码"
                  maxlength="6"
                >
                  <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/code.png')" />
                  <template slot="append">
                    <el-button
                      type="text"
                      style="width: 120px;text-align:center;cursor: pointer;"
                      :loading="form.codeLoading && !code.interval"
                      :disabled="form.codeLoading || !!code.interval"
                      @click="getCode"
                    >
                      {{ code.interval ? `${code.step}s 后重发` : "获取验证码" }}
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item style="margin-top:-10px;">
                <el-checkbox v-model="checked">我同意所有</el-checkbox>
                <a  style="color:#007DF2;text-decoration: none;">劳研研学订阅条款</a>
                ，包含 <a  style="color:#007DF2;text-decoration: none;">隐私条款</a>
              </el-form-item>
              <el-form-item >
                <el-button class="theneBtn" style="width: 100%;padding: 15px 40px;font-size:16px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="enroll">注册</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        
      </div>
      <div class="foot">-江西壹号农业科技有限公司技术支持-</div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
import { REG_EXP } from '@/util'
export default {
  name: 'Login',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.data.checkPass !== "") {
          this.$refs.elForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.data.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
   
    return {
      activePage: 1,
      checked: true,
      form: {
        codeLoading: false,
        data: {
          account: "", // 18807093041
          password: "",
          checkPass: "",
          code: "",
        },
        rules: {
          account: [
            { required: true, message: "请输入手机号", trigger: "change" },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          code: [
            { required: true, message: "请输入验证码", trigger: "change" },
          ],
          password: [
            { validator: validatePass, trigger: "blur" },
            {
              min: 6,
              max: 11,
              message: "密码长度在 6 到 20 个字符",
              trigger: "blur",
            },
          ],
          checkPass: [{ validator: validatePass2, trigger: "blur" }],
        }
      },
      code: {
        loading: false,
        data: "",
        step: 60,
        interval: null
      },
      org: {
        company_id: 0,
        status: '',
      }
    }
  },
  methods: {
    // 验证码倒计时
    setCodeTime() {
      this.code.interval = setInterval(() => {
        if (this.code.step > 1) {
          this.code.step --
        } else {
          clearInterval(this.code.interval)
          this.code.interval = null
          this.code.step = 60
        }
      }, 1000)
    },
    // 获取验证码
    getCode() {
      if(!this.form.data.account) {
        return this.$message.warning('请输入账号')
      }
      this.form.codeLoading = true
      this.setCodeTime()

      return
      console.log('获取验证码！')
      this.$http.post('', {account:this.form.data.account}).then(res => {
        if(res.code == 1) {
          this.setCodeTime()
        }
      }).finally(() => {
        this.form.codeLoading = false
      })
    },
    // 登录
    submit() {
     this.$refs.elForm.validate((valid) => {
        if (valid) {
          let _params = {
            account: this.form.data.account,
            password: this.form.data.password,
          }
          this.$http.post('/government/login/login', _params).then(res => {
            if(res.code === 1) {
              setStorage('token', res.data.token)
              setStorage('userAccount', res.data.account) // 登录账号
              setStorage('userName', res.data.government_name) // 政府名称
              setStorage('province', res.data.province)
              setStorage('city', res.data.city)
              setStorage('area', res.data.area)
              this.$router.push('/school')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 注册
    enroll() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          let _params = {
            account: this.form.data.account,
            password: this.form.data.password,
            confirm: this.form.data.password,
            code: this.form.data.code,
          }
          return
          this.$http.post('/company/account/register', _params).then(res => {
            if(res.code == 1) {
              this.activePage = 1;
              this.$message.success("账号注册成功！");
              this.$refs.elForm.resetFields();
            }
          })
        }
      });
    },
    go_zhuce() {
      this.activePage = 2;
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.elForm.resetFields();
    },
    go_login() {
      this.activePage = 1;
      this.$refs.elForm.resetFields();
    },
  }
}
</script>
<style scoped lang="scss">
.mt30 {
  margin-top: 30px;
}
.login__wrap {
  width: 100%;
  height: 100vh;

  .login__head {
    height: 65px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 60px;
  }
  .logoImg {
    width: 145px;
    height: 34px;
  }

  .login__bg {
    height: calc(100vh - 65px);
    background-image: url('~@/assets/img/bg.png');
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login__main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .login-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #303133;
      margin-top: 0;
    }

    .login__box {
      width: 460px;
      padding: 30px 40px 40px;
      // border-radius: 10px;
      background-color: #fffefe;
      box-sizing: border-box;
      overflow: hidden;
      border: solid 1px #eee;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
  }

  .foot {
    margin-bottom: 40px;
    color: #7C7C7C;
  }
}
.loginTop {
  height: 50px;
  line-height: 50px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 0 25px;
  text-align: right;
  cursor: pointer;
}

</style>

<style scoped>
.text1 {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
}
.sign__main ::v-deep .el-input__inner {
  height: 46px;
  font-family: Microsoft YaHei;
  font-size: 16px;
  padding-left: 40px;
}
.sign__main ::v-deep .el-input__prefix {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.sign__main ::v-deep .el-button {
  font-family: Microsoft YaHei;
  height: 50px;
}
.sign__main ::v-deep .el-checkbox__label {
  color: #333;
}
.portMarking {
  width: 90px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-left: 55px;
  color: #836117;
  font-weight: bold;
  background-image: url('~@/assets/img/sbg.png');
  background-size:90px 36px;
  background-repeat: no-repeat;
}
</style>
